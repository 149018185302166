.MuiInputBase-root {
    background-color: white;
}
.Mui-disabled:not(.MuiFormLabel-root) {
    // opacity: 0.7 !important;
    background-color: rgba(0, 0, 0, 0.02);
    label {
        background-color: transparent;
    }
}
.MuiFormGroup-root .Mui-disabled {
    background-color: transparent !important;
}
