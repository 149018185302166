.summary__logo {
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
}
.summary__container {
    background: linear-gradient(45deg, rgba(102, 3, 90, 0.3) 0%, rgba(200, 8, 136, 0.3) 33%, rgba(98, 86, 174, 0.3) 67%, rgba(0, 163, 213, 0.3) 100%);
    .MuiPaper-root {
        box-shadow: none !important;
    }
}
// .summary__form {
//     &::before {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background: linear-gradient(45deg, rgba(102, 3, 90, 1) 0%, rgba(200, 8, 136, 1) 33%, rgba(98, 86, 174, 1) 67%, rgba(0, 163, 213, 1) 100%);
//         background-clip: border-box;
//         // clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
//     }
// }
.summary__grid {
    position: relative;
    box-sizing: border-box;
    // margin-bottom: 1rem !important;
}
.summary__grid_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 15rem !important;
    transform: translate(-50%, -50%);
    opacity: 0.07;
}
.tableCell__name {
    // color: white !important;
    font-weight: 400 !important;
}
.tableCell__value {
    font-weight: 800 !important;
}
.summary__logo {
    height: fit-content;
}
.summary__grid_buttons .MuiPaper-root {
    // border: 5px solid $color-border;
}
.summary__buttons button {
    height: fit-content;
    // border: 2px solid intherit !important;
    &.yellow-btn {
        border: 2px solid $carrefour-blue !important;
    }
}
.radius-all {
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
}
.template-bg {
    background: #fff;
}
.template-border {
    border: 5px solid rgba(255, 255, 255, 0.2);
}
.summary__grid_buttons {
    background-color: transparent !important;
}
