.registration__page {
    overflow-x: hidden;
}

.registration__wrapper {
    &.slide-in-right {
        transform: translateX(200%);
        // animation-delay: .2s;
        animation-name: slideInFromRight;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    &.slide-in-left {
        transform: translateX(-200%);
        // animation-delay: .2s;
        animation-name: slideInFromLeft;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    &.slide-out-left {
        // animation-delay: 1s;
        animation-name: slideOutLeft;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
}
// .registration__wrapper.slide-out-left {
//     // animation-delay: 1s;
//     animation-name: slideOutLeft;
//     animation-duration: 1s;
//     animation-fill-mode: forwards;
// }
// .registration__welcome-title {

// }
