.app__container {
    min-height: 100vh;
    background: rgb(102, 3, 90);
    background: linear-gradient(45deg, rgba(102, 3, 90, 1) 0%, rgba(200, 8, 136, 1) 33%, rgba(98, 86, 174, 1) 67%, rgba(0, 163, 213, 1) 100%);
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // filter: blur(5px);
    backdrop-filter: blur(4px) grayscale(40%);
    // background-color: rgba(0,0,0,.7);
    // background-color: rgba(255,255,255,.7);
    z-index: 100;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    &.show {
        opacity: 0.7;
    }
}
#modal-root {
    width: 100%;
    height: fit-content;
    position: fixed;
    // overflow: scroll;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 0.2rem;
    .printPageA4 {
        max-height: 97vh;
        padding: 0.2rem;
        max-width: 1280px;
        // margin: auto;
    }
}
