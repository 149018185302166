//3rd party css
@import '~bootstrap/scss/bootstrap';
@import 'react-toastify/dist/ReactToastify.css';
// base, common
@import 'settings/variables', 'base/scaffolding', 'base/typography', 'common/box-sizing', 'common/normalize';

// pages
@import 'pages/contact', 'pages/dashboard', 'pages/registration', 'pages/login', 'pages/summary';

//components
@import 'components/btn', 'components/contractWizard.scss', 'components/form', 'components/notification', 'components/datepicker', 'components/select', 'components/profile', 'components/sidebar',
    'components/topbar', 'components/layout';
