.printPageA4 {
    .contract-language {
        color: transparent;
        &.sign-X {
            color: black;
        }
    }
    .carrefour_sign-2 {
        position: relative;

        &::before {
            content: '';
            height: 1px;
            width: 90%;
            background-color: gray;
            position: absolute;
            top: 70px;
            left: 7%;
        }
    }
    .carrefour_sign {
        position: relative;

        &::before {
            content: '';
            height: 1px;
            width: 90%;
            background-color: gray;
            position: absolute;
            top: 38px;
            left: 10%;
        }
    }
    &.alternate {
        .footer_content {
            font-size: 10px;
        }
        .lightblue {
            background-color: rgb(187, 215, 233);
        }
        .lighterblue {
            background-color: rgba(255, 255, 255, 0.5);
            padding: 3px 10px;
            border-radius: 5px;
        }
        .mobile {
            display: block;
        }
        .color-blue {
            color: rgb(12, 73, 138);
        }
    }
    .contract_number_row {
        h4,
        strong {
            font-size: 17px;
        }
    }
    min-height: auto;
    width: auto;
    padding: 20mm;
    background-color: #fff !important;
    color: black !important;
    .multiple-logo {
        display: flex;
        justify-content: space-around !important;
        align-items: center !important;
    }
}
@media print {
    body,
    html {
        width: 21cm !important;
        height: 28.7cm !important;
        margin: 0;
        padding: 1px;
    }
    .printPageA4 {
        width: 21cm !important;
        height: 28.7cm !important;
        margin: 0;
        padding: 20px;
        font-size: 15px;
        // border: 1px solid #000;
    }
}
@page {
    size: A4;
    margin: 0;
}
