$primary-color-1: #79baf2;
$primary-color-2: #b4cdfa;
$primary-color-3: #3d485b;
$primary-color-4: #750461;
$primary-color-1-hover: lighten($primary-color-1, 10%);
$secondary-color: #da121e;
$secondary-color-hover: darken($secondary-color, 10%);

$primary-text: #f3faffdd;

$primary-bg: #f3faffdd;
$secondary-bg: #f8f8f8;
$carrefour-blue: #004e9f;

$color_icons: black;
$white-text: white;

$color-error: #b30000;
$color-success: #008000;
$color-warning: #f58221;
$color-border: #c1c1c3;
$color-additional: #999999;
$color-gray: #787985;
$color-light-gray: #d8dfe9;

$wizard-disabled-color: #898989;
$passed: #b6178f;
$active: #089dd2;
