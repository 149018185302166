.btn__primary {
    background-color: $primary-color-4 !important;
    color: $primary-text !important;
    &:hover {
        background-color: lighten($primary-color-4, 10) !important;
    }
}
.btn__red {
    background-color: $secondary-color !important;
    color: $white-text !important;
    &.cancelled {
        opacity: 0.6;
    }
}
.btn__carrefour {
    background-color: $carrefour-blue !important;
    color: $white-text !important;
    &.cancelled {
        opacity: 0.6;
    }
}
.black-btn {
    background-color: $color_icons !important;
    color: $primary-text !important;
    width: fit-content !important;
    &-border {
        width: fit-content !important;
        background-color: transparent;
        border: 3px solid $color_icons !important;
        color: black !important;
    }
}
