main {
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 1.6;
}

* {
    box-sizing: inherit;
}

ul,
ol {
    padding-left: 15px;
    margin-bottom: 0;
}

a {
    // white-space: nowrap;
    color: $primary-color-4;
    transition: all 0.3s;

    &:hover {
        text-decoration: none;
        color: lighten($primary-color-4, 10);
    }
}

img {
    width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
